import auth from "@/auth/msal-authprovider.js";
import Fetcher from "@/util/fetcher.js";

const API_URL = process.env.VUE_APP_API_URL;
const API_SCOPES = {
  ACCESS_AS_USER: `api://${process.env.VUE_APP_CLIENT_ID}/access_as_user`
};

const API_REQUESTS = {
  VEHICLES: {
    endpoint: API_URL + "/api/vehicles",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  PROBLEM_SOLVER: {
    endpoint: API_URL + "/api/problem-solvers",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  USER: {
    endpoint: API_URL + "/api/users",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  QUESTION: {
    endpoint: API_URL + "/api/questions",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    }
  },
  AUDIT_REPORT: {
    endpoint: API_URL + "/api/report",
    options: {
      scopes: [API_SCOPES.ACCESS_AS_USER]
    },
  }
};

class GmCvepApi {
  constructor() {
    this.fetcher = new Fetcher(opts => {
      return auth.getTokenRedirect(opts).then(token => {
        return token.accessToken;
      });
    });
    this.API_REQUESTS = API_REQUESTS;
  }

  getAssignedVehicles() {
    return this.fetcher.getJson(API_REQUESTS.VEHICLES, "assigned", "GET", null);
  }

  createProblemReport(vehicleId) {
    return this.fetcher.getJson(API_REQUESTS.VEHICLES, `report-problem/${vehicleId}`, "POST", null);
  }

  getAllModels() {
    return this.fetcher.getJson(API_REQUESTS.VEHICLES, "models", "GET", null);
  }

  markVehicleAsReturned(vehicleId) {
    return this.fetcher.getJson(API_REQUESTS.VEHICLES, `returned/${vehicleId}`, "DELETE", null);
  }

  acceptProblemSolverQuestion(brokerPanelId) {
    return this.fetcher.getJson(API_REQUESTS.VEHICLES, `accept-question/${brokerPanelId}`, "POST", null);
  }

  rejectProblemSolverQuestion(brokerPanelId) {
    return this.fetcher.getJson(API_REQUESTS.VEHICLES, `reject-question/${brokerPanelId}`, "POST", null);
  }

  getCurrentUser() {
    return this.fetcher.getJson(API_REQUESTS.USER, "current", "GET", null);
  }

  updateUserProfile(updatedUser) {
    return this.fetcher.getJson(API_REQUESTS.USER, "profile", "PUT", updatedUser);
  }

  createQuestion(questionForm) {
    return this.fetcher.getJson(API_REQUESTS.QUESTION, "", "POST", questionForm, null);
  }

  getProblemSolverQuestions() {
    return this.fetcher.getJson(API_REQUESTS.QUESTION, "problem-solver-questions", "GET", null);
  }

  updateProblemSolverQuestion(questionId, questionForm) {
    return this.fetcher.getJson(API_REQUESTS.QUESTION, questionId, "PUT", questionForm, null);
  }

  deleteProblemSolverQuestion(questionId) {
    return this.fetcher.getJson(API_REQUESTS.QUESTION, questionId, "DELETE", null);
  }

  undoDeleteProblemSolverQuestion(questionId) {
    return this.fetcher.getJson(API_REQUESTS.QUESTION, questionId + "/undo-delete", "POST", null);
  }

  deleteProblemSolverQuestionImage(questionId) {
    return this.fetcher.getJson(API_REQUESTS.QUESTION, questionId + "/image", "DELETE", null);
  }

  exportAuditReport(request, fileName) {
    return this.fetcher.getJson(API_REQUESTS.AUDIT_REPORT, "export", "POST", request, "application/json", true)
      .then(r => r.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      });
  }

  getReportCountries() {
    return this.fetcher.getJson(API_REQUESTS.AUDIT_REPORT, "countries", "GET", null);
  }
}

export default new GmCvepApi();

import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { logger } from "@/util/logger.js";

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {    
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + process.env.VUE_APP_TENANT_ID,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    validateAuthority: true
  },
  cache: {
    cacheLocation: "sessionStorage"
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (logger.shouldLog()) {
          switch (level) {
            case LogLevel.Error:
              logger.error(message);
              return;
            case LogLevel.Info:
              logger.info(message);
              return;
            case LogLevel.Verbose:
              logger.debug(message);
              return;
            case LogLevel.Warning:
              logger.warn(message);
              return;
            default:
              return;
          }
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance };

export const loginRequest = {  
  scopes: ["User.Read"],
  redirectUri: window.location.origin
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const tokenConfig = {
  development : {
    userIdClaim: "oid"
  },
  staging : {
    userIdClaim: "gmuniqueidentifier"
  },
  production : {
    userIdClaim: "gmuniqueidentifier"
  }
}